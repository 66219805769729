import React from 'react'
import Header from '../Header'
import { Container, Row, Col } from 'react-bootstrap'
import { BsSearch, BsPencil } from 'react-icons/bs'
import { Input } from '@mui/joy'
import '../style/calender.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import icon from '../assests/🦆 icon _menu_.png'
import vector from '../assests/Vector.png'
import calenderovector from '../assests/calendervector.png'
import cakephoto from '../assests/cakeimg.png'
import plusimg from '../assests/plusimg.png'
import greencake from '../assests/greencake.png'
import bluecake from '../assests/bluecake.png'
import Button from 'react-bootstrap/Button';
import calender from '../assests/calender.png'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GiStairsCake } from 'react-icons/gi'
import Pagination from '@mui/material/Pagination';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from '@mui/material/Skeleton';
// import { SuccessToast,ErrorToast } from '../helper/Toast'
import { BsInfoCircle } from 'react-icons/bs'
import { Swipe, SwiperSlide } from 'swiper/react'
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
} from "mdb-react-ui-kit";
import { AiOutlineMenu } from 'react-icons/ai'
import { makeStyles } from '@material-ui/core/styles';
import { SuccessToast } from '../helper/Toast'



const useStyles = makeStyles({
    indicator: {
        height: '4px',
        borderRadius: '4px',
        backgroundColor: 'blue'
    },
});

const Calender = () => {
    const [value, setValue] = React.useState('one');
    const [data, setData] = useState([])
    const [serch, setSerch] = useState('')
    const [pagesize, setpagesize] = useState(20);
    const [currentpage, setcurrentpage] = useState(1);
    const [tab, setTab] = useState([])
    const [category, setCategory] = useState("")
    const [all, setAll] = useState([])
    const [calender, setCalender] = useState("")
    const [totalpage, settotalpage] = useState(0);
    const [extraflag, setextraflag] = useState(true)

    const [isClickedleft, setIsClickedleft] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [contryData, setCountryData] = useState([])
    const [countryName, setCountryName] = useState("")
    const [active, setactive] = useState(true)
    const [basicActive, setBasicActive] = useState("");
    const [totalCount, setTotalCount] = useState("");

    const classes = useStyles();


    console.log("countryName", countryName)




    const navigate = useNavigate();
    const Body = {

        "search": serch,
        "country": countryName,
        "categoryName": category,
        "limit": pagesize,
        "page": currentpage

    }

    useEffect(() => {
        const a = [];
        axios.get('https://api.app.specialdays.com/user/get/category/without/tokon')
            .then(res => {
                // SuccessToast("gasdjklhafDGK, ASDYG ")
                setTab(res?.data?.data?.data);
                setAll(res?.data?.data?.totalrecord)
                a.push(
                    res?.data?.data?.data.map((res, index) => {
                        return res.totalCount;
                    })
                );
                let sum = a[0].reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                );
                setTotalCount(sum);
            })
            .catch(error => {
                console.error(error);
            });
    }, [serch, currentpage, pagesize]);

    useEffect(() => {
        axios.get('https://api.app.specialdays.com/user/get/country/without/tokon')
            .then(res => {
                // console.log("calender data", res?.data?.data?.response)

                setCountryData(res?.data?.data);

            })
            .catch(error => {
                console.error(error);
            });
    }, [serch, currentpage, pagesize, category]);

    const handleChangepagination = (e, i) => {
        setcurrentpage(i)
    };

    useEffect(() => {
        axios.post('https://api.app.specialdays.com/user/get/calender/without/tokon', Body)
            .then(res => {
                // console.log("calender data", res?.data?.data?.response)
                setData(res?.data?.data?.response);
                // console.log('data :>> ', data);
                settotalpage(res?.data?.data?.page_data?.page_limit)
            })
            .catch(error => {
                console.error(error);
            });
        setIsClicked(!isClicked);
        setIsClickedleft(true)
    }, [serch, currentpage, pagesize, category, countryName]);



    console.log('currentpage :>> ', currentpage);

    const handlecarddetails = (i, e, m) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth" // Add smooth scrolling effect
        });
        let q = 0;

        const length = data.map((item, i1) => {
            let key = i1;
            console.log(item.data.length, i1, m)

            if ((m - 1) > key) {
                q = q + item.data.length

            }

        })
        let h = (q + (e));
        let j = (currentpage - 1) * pagesize + h
        let x = (currentpage * h);

        setCalender(i)


        navigate(`/Reminder_data?j=${j}&currentpage=${currentpage}&category=${category}&countryName=${countryName}&serch=${serch}`, { state: { i, j, currentpage, pagesize, category, countryName, serch } })
    }
    console.log('calender :>> ', calender);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleonchnagespagination = (e) => {
        setCountryName(e.target.value);
    }

    const handletab = (i, index) => {
        setextraflag(index);
        console.log("i :>> ", i);
        setCategory(i);
        setIsClicked(!isClicked);
        setBasicActive(index);
        // setIsClickedleft(true);
    };
    const handleall = (i) => {
        setBasicActive("");
        setCategory(i);
        console.log("juhil", i);
    };

    // const handletab = (i,j) =>{
    //     setextraflag(j)
    //     console.log('i :>> ', i);
    //     setCategory(i)
    //     setIsClicked(!isClicked);
    //     setIsClickedleft(true)
    // }
    // const handleall=(i)=>{
    //     setCategory(i)
    // }


    return (
        <div className='calendermaindiv'>
            <Header />
            <br />
            <br />
            {/* <br /> */}
            <Container>
                <h2 className='headingcalender' style={{ fontSize: '3rem', fontWeight: 'bold' }}>Special Days Events Calendar </h2>
            <br />
                <div style={{   fontSize: "1.1rem",fontWeight: "600"}}>Our calendar is a great way to enjoy events, holidays, observances, religious occasions and many more special days in the UK and from around the world. Create an account to create your own personal calendar including your own events to help plan the year ahead.</div>
            </Container>
            <br />
            <br />
            {/* <br /> */}

            <Container className='serchbarcalender_main_outside'>




                <Row id="sele" className='serchbarcalender_main d-flex justify-content-center'>
                    <Col lg={10} sm={6} className='serchbarcalender'>
                        <Input
                            startDecorator={<BsSearch style={{ marginLeft: '20px' }} />}
                            type="text"
                            placeholder="Search your events"
                            className='serchbar'
                            onChange={(e) => {
                                setSerch(e.target.value);
                            }}
                            style={{ borderRadius: "40px", height: "50px", padding: '10px' }}
                        ></Input>
                    </Col>
                    <Col className="selecbtnshow dropdown" id="selectshow" lg={2} sm={6} style={{ paddingLeft: '20px', borderRadius: '25px' , height:"50px" }}>
                        Show
                        <select
                            className='selectcountry'
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            // className="textfieldinput"
                            onChange={(e) => handleonchnagespagination(e)}
                            value={countryName}
                            style={{ backgroundColor: '#FF3333', color: '#ffffff', border: '1px solid #FF3333', outline: 'none', width: '120px' }}
                        >
                            <option value='' style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000' }} className='all'>All</option>
                            {contryData?.map((val) => {
                                return (
                                    <option style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000', width: '50px' }} value={val?.countryName}>{val?.countryName}</option>
                                )
                            })}


                            {/* <MenuItem value="Gender">Gender</MenuItem> */}
                            {/* <MenuItem value={10}>10</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={70}>70</MenuItem> */}
                        </select>
                    </Col>
                </Row>
            </Container>
            <br />


            <Container>
                <Row id='tab'>

                    <Col>
                        <Box sx={{ width: '100%' }}>

                            <Tabs

                                onChange={handleChange}

                                style={{ overflowX: 'auto' }}
                            >
                                <MDBTabs className="mb-1">
                                    <MDBTabsItem className='tabsindicator'>
                                        <MDBTabsLink
                                            className={basicActive === "" ? "nav-linkactive" : ""}
                                            onClick={(e) => handleall("")}
                                            // active={basicActive === tabs.categoryName}
                                            style={{ overflowX: "auto" }}
                                        >
                                            <div
                                                className={
                                                    basicActive === "" ? "spantxtactive" : "spantxt"
                                                }
                                            >
                                                All
                                            </div>
                                            <div
                                                className={totalCount == '' ? ('') : (
                                                    basicActive === "" ? "spannumactive" : "spannum")
                                                }
                                            >
                                                {totalCount}
                                            </div>
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                </MDBTabs>

                                {tab.length > 0 ? (
                                    tab.map((tabs, index) => (
                                        <React.Fragment key={tabs.categoryName}>
                                            <MDBTabs className="mb-1">
                                                <MDBTabsItem>
                                                    <MDBTabsLink
                                                        className={
                                                            basicActive === index ? "nav-linkactive" : ""
                                                        }
                                                        onClick={() => handletab(tabs.categoryName, index)}
                                                    >
                                                        <div
                                                            className={
                                                                basicActive === index
                                                                    ? "spantxtactive"
                                                                    : "spantxt"
                                                            }
                                                        >
                                                            {tabs.categoryName}
                                                        </div>
                                                        <div
                                                            className={
                                                                basicActive === index
                                                                    ? "spannumactive"
                                                                    : "spannum"
                                                            }
                                                        >
                                                            {tabs.totalCount}
                                                        </div>
                                                    </MDBTabsLink>
                                                </MDBTabsItem>
                                            </MDBTabs>
                                        </React.Fragment>
                                    )

                                    )
                                ) : (
                                    <React.Fragment>
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />

                                    </React.Fragment>
                                )}
                            </Tabs>
                        </Box>
                    </Col>

                </Row>
                <br />

                {data.length !== 0 ? (data.map((data1, index1) => {
                    let month = data1._id.month
                    console.log('main  index1 :>> ', index1);
                    return (
                        <>
                            <Row>
                                <Col xs="auto"
                                    className='month'
                                // style={{marginLeft:'70px'}}
                                >
                                    <span style={{ fontSize: '1.5rem', fontWeight: '600' }}>{moment(month, 'M').format('MMMM')} {data1._id.year}</span>

                                </Col>

                            </Row><br />


                            {data1.data.map((item, index) => {
                                let y;
                                let Bday;
                                const date = moment(item?.date).utc()
                                console.log('date :>> ', date);
                                console.log('sub index :>> ', index);

                                let today = moment().format('YYYY-MM-DD')
                                let fDate = moment(new Date(item?.date)).format("YYYY-MM-DD")
                                console.log('fDate :>> ', fDate);
                                let nDate = new Date().getFullYear()
                                let futureDate = moment(new Date(`${nDate}-${fDate?.split("-")[1]}-${fDate?.split("-")[2]}`)).format("YYYY-MM-DD")


                                let days = Math.round(new Date(fDate) - new Date(today) / (1000 * 60 * 60 * 24))
                                Bday = moment(fDate).diff(moment(new Date()), "days")

                                return (
                                    <>

                                        {/* <br /> */}
                                        <Row style={{ backgroundColor: '#FFFFFF', borderRadius: '28px', width: '100%', marginLeft: '0px' }} className='text-start py-3 align-items-center calenderdiv' >

                                            <Col xs={12} md={2} className='ps-4'>
                                                <div className='d-flex gap-3  w-fit align-items-center justify-content-center '>
                                                    <div>
                                                        <img src={calenderovector} className="w-5 calenderim" style={{ width: '25px' }}></img>
                                                    </div>
                                                    <div style={{ fontSize: '1.2rem', fontWeight: '600' }} className='text'>{moment(date).format("DD")} {moment(date).format("ddd")}</div>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <div className="row align-items-center colortab pe-5">
                                                    <div className="col-3 calendercolor" style={{ backgroundColor: `${item.color}`, width: '35px', height: '35px', borderRadius: '50px' }}>

                                                    </div>
                                                    <div className='col-9 text4' style={{ fontSize: '1.3rem', fontWeight: '600' }}>{item.name}</div>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={4} >
                                                <div className='me-5 text2' style={{ fontSize: '1.1rem', fontWeight: '400' }}>{Bday === 0 ? `${item.categoryName} is today ` : `${item.categoryName} in ${Bday} days`}</div>
                                            </Col>

                                            <Col xs={6} md={2} style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }} className='text-end finalrow'>



                                                <div className='infoicon' onClick={(e) => { handlecarddetails(item, index + 1, index1 + 1) }}
                                                    style={
                                                        {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: "#DADADA",
                                                            borderRadius: "25px",
                                                            padding: "10px",
                                                            cursor: 'pointer'
                                                        }
                                                    }>
                                                    <AiOutlineMenu style={{ color: "#00000" }} />
                                                </div>

                                                <img src={plusimg} className="w-10 plusimg" style={{ width: '35px', cursor: 'pointer' }} onClick={() => navigate('/login')}></img>
                                            </Col>

                                        </Row><br />

                                    </>

                                )

                            }
                            )}
                        </>

                    )
                })) : (<h1>No data found</h1>)}
                <br />



                {data.length !== 0 ? (
                    <Row>
                        <Col >
                            <Box

                            >
                                <Pagination
                                    count={totalpage}
                                    page={currentpage}
                                    className='harsh'

                                    onChange={(event, page) => {
                                        handleChangepagination(event, page);
                                        window.scrollTo(0, 0);
                                    }}

                                />
                            </Box>

                        </Col>
                    </Row>
                ) : ('')}

                <br /><br />
            </Container>

            <Footer />
        </div>

    )
}

export default Calender