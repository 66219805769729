import React, { useEffect, useState } from 'react'
import '../style/signup.css'
import specialdaylogo from '../assests/spd-logo-3 1.png'
import home from '../assests/home.png'
import reminder from '../assests/reminder.png'
import Input from '@mui/joy/Input';
import { CiMail } from 'react-icons/ci'
import { CiLock } from 'react-icons/ci'
import { SlCalender } from 'react-icons/sl'
import { BiUser } from 'react-icons/bi'
import { BsEyeSlash,BsEye } from 'react-icons/bs'
import { FcGoogle } from 'react-icons/fc'
import { red } from '@mui/material/colors';
// import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { BsFacebook, BsApple } from 'react-icons/bs'
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import Autocomplete from '@mui/joy/Autocomplete';
import { SuccessToast,ErrorToast } from '../helper/Toast' 
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';


const clientId ="861261401828-l061j3g4e3fm013obaslkrh6rrvc2n84.apps.googleusercontent.com";

const Signup = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [newData, setNewData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dob: "",
    gender: "",
  })
  console.log('newData', newData)


  const handleChange = (e) => {
    setNewData({ ...newData, [e.target.name]: e.target.value })
    setPassword(e.target.value);
  }


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const gender = [
    { label: 'Male', value: 0, id: 1 },
    { label: 'Female', value: 1, id: 2 },
  ];

  const handleLogin = async () => {
    let body = {
      firstName: newData?.firstName,
      lastName: newData?.lastName,
      email: newData?.email,
      password: newData?.password,
      dob: newData?.dob,
      gender: newData?.gender,
      userType: 0,
      deviceToken: ["abcxyz"]
    }
    await axios.post('https://api.app.specialdays.com/auth/signUp', body)
      .then(res => {
        console.log('res', res)
        navigate("/login")
        SuccessToast("Register Successfull")
      })
      .catch(error => {
        ErrorToast("please fill all details");
        console.error(error);
      });

  }

  const handleGoogleLogin = async(response) => {
    const idToken = response.getAuthResponse().id_token;
    const accessToken = response.getAuthResponse().access_token;
    let UserInfo = response.profileObj;
    let body = {
      idToken: idToken,
      accessToken: accessToken,
      deviceToken: [
          "dsfgsdfgsd"
      ]
  }
  if(accessToken){await axios.post("https://api.app.specialdays.com/auth/google_login",body).then((res)=>{
      console.log('googleres', res)
      if(res?.data?.message=="Login successful!"){
      localStorage.setItem("userInfo", JSON.stringify(res));
      navigate('/calendar')
      SuccessToast("Logged in Successfully!");
      }else{
        ErrorToast("something went wrong")
      }
    }).catch((err)=>{
      ErrorToast(err?.message)
    })}
    
    console.log("Login Success:",UserInfo);
    console.log('ID token:', idToken);
    console.log('Access token:', accessToken);
  
  }
  

  const handleFacebookLogin = async(response) => {
    console.log('facebook login',response)
    const accessToken = response.accessToken;
    console.log('Access token:', accessToken);
    let UserInfo = response.name;
  let body={
    accessToken:accessToken ,
    deviceToken:  ['asf']
  }
    
  if(accessToken){await axios.post("https://api.app.specialdays.com/auth/facebook_login",body).then((res)=>{
    console.log('facebookres', res)
  
    if(res?.data?.message=="Login successful!"){
      console.log('facebookres', res)
      localStorage.setItem("userInfo", JSON.stringify(res));
      navigate('/calendar')
      SuccessToast("Loggin successfull")
    }else{
      ErrorToast("Something Wrong")
    }
    
  }).catch((err)=>{
    ErrorToast(err?.message)
  })
  }
  }

  return (
    <div style={{ backgroundColor: '#F1F5FA' }}>
      <Container style={{display:"flex", justifyContent:"center", alignItems:"center"}} >

        {/* <Row style={{ backgroundColor: '#F1F5FA',borderRadius:'35px' }}>
          <Col>
            <img src={specialdaylogo} className='signuplogo'></img>
          </Col>
        </Row>
        <br /><br /> */}


        <Row style={{ backgroundColor: '#FFFFFF',paddingRight:"0px" ,borderRadius:"36px",marginTop: "30px" }} className='container'>
        <Col style={{ marginTop: "35px" }} className='signuplogo' >
        {/* <div className='container'> */}
        <img src={specialdaylogo} className='mainimg'></img>
            <h4 style={{marginBottom:"20px"}}>Sign up to get started</h4>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <Row className='flname_main' style={{marginBottom:"10px", width: "70%", gap:"15px"}}>
              <Col className='flname' lg={4} sm={12} md={6} style={{padding:"0px" , width:"48%"}} >
                <Input  startDecorator={<BiUser />} name="firstName" placeholder="First Name" variant="soft" size='sm'  onChange={handleChange} className='signupinputs'></Input>

              </Col>
              <Col className='flname' lg={4} sm={12} md={6} style={{padding:"0px" , width:"48%"}}>
                <Input startDecorator={<BiUser />} name="lastName" placeholder="Last Name" variant="soft" size='sm'  onChange={handleChange} className='signupinputs'></Input>

              </Col>

            </Row>
            
            <Input style={{marginBottom:"10px", width: "70%"}} startDecorator={<CiMail />} className='signupinputs' name="email" placeholder="Email" variant="soft" size='sm'  onChange={handleChange}></Input>
            {/* <Input startDecorator={<CiLock />} className='signupinputs' name="password" placeholder="Password" variant="soft" size='sm'  endDecorator={<BsEyeSlash />} onChange={handleChange}></Input><br /> */}
            <Input
            style={{marginBottom:"10px", width: "70%"}}
      startDecorator={<CiLock />}
      className='signupinputs'
      name="password"
      placeholder="Password"
      variant="soft"
      size='sm'
      
      type={showPassword ? 'text' : 'password'}
      endDecorator={showPassword ?   <BsEye onClick={toggleShowPassword} /> : <BsEyeSlash onClick={toggleShowPassword} />}
      onChange={handleChange}
    />
            <Input style={{marginBottom:"10px", width: "70%"}} type='date' className='signupinputs' startDecorator={<SlCalender />} name="dob" placeholder="Date of Birth" variant="soft" size='sm'  endDecorator='' onChange={handleChange}></Input>
<div style={{width: "70%"}}>
            <Autocomplete
            style={{marginBottom:"20px"}}
              startDecorator={<BiUser />}
              placeholder="Gender"
              className='w-100 signupinputs autocomplete'
              variant="soft"
              size='sm'
              onChange={(e) => {
                console.log('easd', e.target.innerText)
                if (e.target.innerText === "Male") {
                  setNewData({ ...newData, gender: 0 })
                } else {
                  setNewData({ ...newData, gender: 1 })
                }
              }}

              options={gender}
              sx={{ width: 300 }}
            ></Autocomplete>
</div>

          </div> 


            <Button variant='danger' className='signupinputs' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px', marginBottom:"20px" }} onClick={handleLogin}>Sign up</Button>
            
            <div style={{display:"flex" , justifyContent:"center"}}>
                                                            <div className="position-absolute z-2" style={{  marginTop: "-3px", display:"flex", flexDirection:"row", width:"23%", justifyContent:"center" }} >
                                                                <hr className="w-100" style={{ border: "1px solid #0000001f" }} />
                                                            </div>
                                                            <span style={{ position: "-webkit-sticky", position: "sticky", zIndex: "3" }} >
                                                            <h6 style={{marginBottom:"20px", fontSize:"16px", backgroundColor:"white" , border:"5px solid white"}}>OR</h6></span></div>
            

            {/* <Button variant=''  style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black' }} className='signupgoogle signupinputs'> <FcGoogle style={{ width: '50px', height: '25px' }}  />Continue With Google</Button>
             */}
               <GoogleLogin 
      clientId={clientId}
      buttonText="Continue with Google"
      onSuccess={handleGoogleLogin}
      cookiePolicy={'single_host_origin'}
      render={renderProps => (
        <button 
        className='logingoogle' 
          style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black',backgroundColor:'#ffffff',color:'black', marginBottom:"10px" }}
          onClick={renderProps.onClick}
        >
          <FcGoogle style={{ width: '50px', height: '25px' }} />
          Continue with Google
        </button>
      )}
    />
           
            {/* <Button variant='' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black' }}  className='signupgoogle signupinputs'><BsFacebook style={{ width: '50px', height: '25px' }} />Continue With Facebook</Button>
             */} <FacebookLogin
      // appId='3122858851340111'
      appId='1314681382599453'
      fields="name,email,picture"
      callback={handleFacebookLogin}
      autoLoad={false}

      render={renderProps => (
        <div style={{display:"flex", justifyContent:"center"}}>
        <button
        className='logingoogle' 
          style={{ display:"flex", justifyContent:"center", fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black',marginBottom:"10px", gap:"10px",backgroundColor:'#ffffff',color:'black' }}
          onClick={renderProps.onClick}
        >
          <BsFacebook style={{ width: '25px', height: '25px', color:" #3b5998" }} />
          <span>Continue with Facebook</span>
        </button> </div>
      )}
    />
           
            <Button variant='' style={{ fontWeight: '600', borderRadius: '20px', width: '70%',  border: '1px solid black',marginBottom:"25px" }}  className='signupgoogle signupinputs'><BsApple style={{ width: '50px', height: '25px' }} />Continue With Apple</Button>
          
            <h6 className='signupinputs'>By clicking Register,you agree to out</h6>
            <h6 style={{ color: '#FF3333' ,marginBottom:"20px" }}>Privacy policy & Terms of Use</h6>
          

            <h6 className='signupinputs'>Already have an account ? <span className='signupinputs' style={{ color: '#FF3333', fontWeight: "600", cursor: 'pointer' }} onClick={()=>navigate("/login")}> Login</span></h6>
        {/* </div> */}
          </Col>





          <Col className='reminderimgdiv'>
            <img src={reminder} className='reminderimg' style={{height:"100%" , width:"100%"}}/>
          </Col>
          

        </Row>
      </Container>
    </div>



    //     <div className='main'>
    //     <img src={specialdaylogo} className='speciallogo' />
    //     <div className='login'><br/><br/><br/><br/>

    //         <p className='logintext'>Sign up to get started</p>
    //         <div className='signupi'>
    //         <Input startDecorator={<BiUser/>} placeholder="Firest Name" variant="soft" size='sm' style={{ height: 40, width: "200px" }} className='input' ></Input><br />
    //         <Input  startDecorator={<BiUser/>}  placeholder="Last Name" variant="soft" size='sm' style={{ height: 40, width: "200px" }} className='input2' ></Input>
    //         </div><br/>
    //         <Input startDecorator={<CiMail/>} placeholder="Email" variant="soft" size='sm' style={{ height: 40, width: "440px" }} className='input' ></Input><br />
    //         <Input  startDecorator={<CiLock/>}  placeholder="Password" variant="soft" size='sm' style={{ height: 40, width: "440px" }} className='input' endDecorator={<BsEyeSlash/>}></Input><br/>
    //         <Input   startDecorator={<SlCalender/>} placeholder="Date of Birth" variant="soft" size='sm' style={{ height: 40, width: "440px" }} className='input' endDecorator=''></Input><br />
    //         <Autocomplete
    //         startDecorator={<BiUser/>}
    //       placeholder="Gender"
    //       style={{ height: 40, width: "440px" }} 
    //       variant="soft"
    //        size='sm' 
    //       className='input' 
    //       options={gender}
    //       sx={{ width: 300 }}
    //     />

    //         <br/><br/>
    //         <button className='loginbtn' >Sign Up</button>
    //         <h3 style={{ marginLeft: -120 }}>OR</h3>
    //         <button  className='loginbtngl'><FcGoogle />Continue With Google</button><br/><br/>
    //         <button className='loginbtngl'><BsFacebook />Continue With Facebook</button><br/><br/>
    //         <button className='loginbtngl'><BsApple/>Continue With Apple</button><br/><br/>
    //         <h3 style={{ marginLeft:-120,fontWeight:300}} > By clickign Register.you agree to out</h3>
    //         <h3 style={{ marginLeft:-120,fontWeight:600}}  className='policy'>Privacy policy & Terms of Use</h3>
    //         <h3 style={{ marginLeft:-120,fontWeight:300}}  className='privacy'>Already have an account?<span className='here' onClick={()=>navigate("/")}>Login</span></h3>    
    //     </div>
    //     <div className='photo'>
    //         <img src={reminder} className='homeimg2'/>
    //     </div>
    // </div>

  )
}

export default Signup