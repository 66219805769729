import logo from './logo.svg';
import './App.css';
import Login from './component/Login';
import { Routes, Route, Navigate } from "react-router-dom";
import Signup from './component/Signup';
import Dashbord from './component/Dashbord';
import Calender from './component/Calender';
import Reminder from './component/Reminder';
import Discount from './component/Discount';
import Fashion from './component/Fashion';
import Dashbord2 from './component/Dashbord2';
// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
        <Routes> 
        <Route path="/" element={<Navigate to="/calendar" />} />
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/dashbord" element={<Dashbord />}></Route>
        <Route path="/calendar" element={<Calender />}></Route>
        <Route path="/Reminder_data" element={<Reminder />}></Route>
        <Route path="/discount" element={<Discount />}></Route>
        <Route path="/fashion" element={<Fashion />}></Route>
        <Route path="/reminder" element={<Dashbord2 />}></Route>
        <Route path="/plan_a_party" element={<Dashbord2 />}></Route>






        </Routes>
      
      {/* <Login/> */}
    </div>
  );
}

export default App;
