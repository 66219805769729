import React, { useEffect, useState } from 'react'
import specialdaylogo from '../assests/spd-logo-3 1.png'
import home from '../assests/home.png'
import '../style/Login.css'
import Input from '@mui/joy/Input';
import { CiMail } from 'react-icons/ci'
import { CiLock } from 'react-icons/ci'
import { BsEyeSlash,BsEye  } from 'react-icons/bs'
import { FcGoogle } from 'react-icons/fc'
import { red } from '@mui/material/colors';
// import { Button } from '@mui/material';
import { BsFacebook, BsApple } from 'react-icons/bs'
import { useNavigate } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import { SuccessToast, ErrorToast } from '../helper/Toast'
// import { GoogleAuth } from 'google-auth-library';
// import { gapi} from 'gapi';
import { loadGapiInsideDOM } from "gapi-script";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import FacebookLogin from 'react-facebook-login'
import {LoginSocialFacebook } from 'reactjs-social-login'
import AppleLogin from 'react-apple-login';

const clientId ="861261401828-l061j3g4e3fm013obaslkrh6rrvc2n84.apps.googleusercontent.com";
const appId = '598704202205918';

const Login = () => {
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [newData, setNewData] = useState({
    email: "",
    password: "",
  })


  const handleChange = (e) => {
    setNewData({ ...newData, [e.target.name]: e.target.value })
    setPassword(e.target.value);
  }
  console.log('newData', newData)

 

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    let body = {
      email: newData?.email,
      password: newData?.password,
      deviceToken: ["abcxyz"]
    }
    await axios.post('https://api.app.specialdays.com/auth/logIn', body)
      .then(res => {

        console.log('res', res)
        navigate("/calendar")
        SuccessToast('Login Successfull')
        localStorage.setItem("userInfo", JSON.stringify(res?.data?.data?.response))
      
      })
      .catch(error => {
        ErrorToast("incorrect password or username");
        console.error(error);
      });

  }

     
const handleGoogleLogin = async(response) => {
  const idToken = response.getAuthResponse().id_token;
  const accessToken = response.getAuthResponse().access_token;
  let UserInfo = response.profileObj;
  let body = {
    idToken: idToken,
    accessToken: accessToken,
    deviceToken: 
        "dsfgsdfgsd"
    
}
if(accessToken){await axios.post("https://api.app.specialdays.com/auth/google_login",body).then((res)=>{
    console.log('googleres', res)
    if(res?.data?.message=="Login successful!"){
    localStorage.setItem("userInfo", JSON.stringify(res));
    navigate('/calendar')
    SuccessToast("Logged in Successfully!");
    }else{
      ErrorToast("something went wrong")
    }
  }).catch((err)=>{
    ErrorToast(err?.message)
  })}
  
  console.log("Login Success:",UserInfo);
  console.log('ID token:', idToken);
  console.log('Access token:', accessToken);

}


const handleFacebookLogin = async(response) => {
  console.log('facebook login',response)
  const accessToken = response.accessToken;
  console.log('Access token:', accessToken);
  let UserInfo = response.name;
let body={
  accessToken:accessToken ,
  deviceToken:  ['asf']
}
  
if(accessToken){await axios.post("https://api.app.specialdays.com/auth/facebook_login",body).then((res)=>{
  console.log('facebookres', res)

  if(res?.data?.message=="Login successful!"){
    console.log('facebookres', res)
    localStorage.setItem("userInfo", JSON.stringify(res));
    navigate('/calendar')
    SuccessToast("Loggin successfull")
  }else{
    ErrorToast("Something Wrong")
  }
  
}).catch((err)=>{
  ErrorToast(err?.message)
})
}
}
// function handleFacebookLogin() {
//   window.FB.login(function(response) {
//     console.log('btn click' )
//     if (response.authResponse) {
//       console.log('Access Token:', response.authResponse.accessToken);
//     } else {
//       console.log('User cancelled login or did not fully authorize.');
//     }
//   });
// }



// useEffect(() => {
//   window.fbAsyncInit = function() {
//     window.FB.init({
//       appId      : 'your-app-id',
//       cookie     : true,
//       xfbml      : true,
//       version    : 'v12.0'
//     });
//   };

//   (function(d, s, id) {
//     var js, fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) return;
//     js = d.createElement(s); js.id = id;
//     js.src = "https://connect.facebook.net/en_US/sdk.js";
//     fjs.parentNode.insertBefore(js, fjs);
//   }(document, 'script', 'facebook-jssdk'));
// }, []);




// function handleFacebookLogin() {
//   if (typeof FB !== 'undefined') {
//     window.FB.login(function(response) {
//       if (response.authResponse) {
//         console.log('Welcome! Fetching your information.... ');
//         window.FB.api('/me', function(response) {
//           console.log('Good to see you, ' + response.name + '.');
//         });
//       } else {
//         console.log('User cancelled login or did not fully authorize.');
//       }
//     });
//   }
// }

const handleAppleLoginSuccess = (response) => {
  // Handle the successful login response
  console.log(response);
};

const handleAppleLoginFailure = (error) => {
  // Handle the failed login response
  console.error(error);
};

const handleClick = () => {
  // Open the Apple Login popup window
  window.AppleID.auth.init({
    clientId: 'your-client-id',
    scope: 'email name',
    redirectURI: 'your-redirect-uri',
    state: 'your-state',
    usePopup: true,
  });

  // Handle the login response
  window.AppleID.auth.signIn().then(handleAppleLoginSuccess).catch(handleAppleLoginFailure);
};


  return (
    <div style={{ backgroundColor: '#F1F5FA' }}>
      <Container style={{display:"flex", justifyContent:"center", alignItems:"center"}} >

        {/* <Row style={{ backgroundColor: '#F1F5FA' }}>
          <Col>
            <img src={specialdaylogo} className='mainimg'></img>
          </Col>
        </Row>
        <br /><br /> */}


        <Row style={{ backgroundColor: '#FFFFFF',borderRadius:'36px',marginTop: "30px", maxWidth:"100%", width:"100%"  }}>
          <Col style={{ marginTop: "40px" }} className='logindiv'>
          <img src={specialdaylogo} className='mainimg'></img>
          <div style={{display:"flex", justifyContent:"center"}}>

            <h4 className='logintxt' style={{marginBottom:"20px", fontSize:"18px", display:"flex", justifyContent:"center" , width:"70%"}}>Please log in or create an account to create your personalised Special Days calendar and gain access to exclusive partner discounts only available to our members.</h4>
          </div>
            <Input  startDecorator={<CiMail />} placeholder="Email" name='email' variant="soft" size='sm' className='inputfeild' style={{ height: 40, width: "70%", margin: 'auto', marginBottom:"10px" }} onChange={handleChange}></Input>
            <Input 
      startDecorator={<CiLock />}
      type={showPassword ? 'text' : 'password'}
      placeholder="Password"
      name='password'
      variant="soft"
      size='sm'
      className='passwrodfeild'
      style={{ height: 40, width: "70%", margin: 'auto' , marginBottom:"20px"}}
      onChange={handleChange}
      endDecorator={showPassword ?  <BsEye onClick={toggleShowPassword} /> :  <BsEyeSlash onClick={toggleShowPassword} />}
    />
            {/* <Input startDecorator={<CiLock />} type='password' placeholder="Password" name='password' variant="soft" size='sm' className='passwrodfeild' style={{ height: 40, width: "70%", margin: 'auto' }} onChange={handleChange} endDecorator={<BsEyeSlash />}></Input> */}
           

            <h6 style={{ color: '#FF3333', fontWeight: "700" , marginBottom:"20px"}}> Forgot Your Password ?</h6>

            <Button variant='danger' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px' , marginBottom:"20px"}} onClick={handleLogin}>Log In</Button>
            
            <div style={{display:"flex" , justifyContent:"center"}}>
                                                            <div className="position-absolute z-2" style={{  marginTop: "-3px", display:"flex", flexDirection:"row", width:"23%", justifyContent:"center" }} >
                                                                <hr className="w-100" style={{ border: "1px solid #0000001f" }} />
                                                            </div>
                                                            <span style={{ position: "-webkit-sticky", position: "sticky", zIndex: "3" }} >
                                                            <h6 style={{marginBottom:"20px", fontSize:"16px", backgroundColor:"white" , border:"5px solid white"}}>OR</h6></span></div>

            

            {/* <Button variant='' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px', border: '1px solid black' }}  ><FcGoogle style={{ width: '50px', height: '25px' }}/>Continue With Google</Button> */}
           
        
           <GoogleLogin
      clientId={clientId}
      buttonText="Continue with Google"
      onSuccess={handleGoogleLogin}
      cookiePolicy={'single_host_origin'}
      render={renderProps => (
        <button
        className='logingoogle' 
          style={{ fontWeight: '600', borderRadius: '20px', marginBottom:"10px", width: '70%', border: '1px solid black',backgroundColor:'#ffffff',color:'black' }}
          onClick={renderProps.onClick}
        >
          <FcGoogle style={{ width: '50px', height: '25px' }} />
          Continue with Google
        </button>
      )}
    />
            
             {/* <Button variant='' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px', border: '1px solid black' }}><BsFacebook style={{ width: '50px', height: '25px' }} onClick={handleFacebookLogin}/>Continue With Facebook</Button> */}
     <FacebookLogin
      // appId='3122858851340111'
      appId='1314681382599453'
      fields="name,email,picture"
      callback={handleFacebookLogin}
      autoLoad={false}

      render={renderProps => (
        <div style={{display:"flex", justifyContent:"center"}}>
        <button
        className='logingoogle' 
          style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black',backgroundColor:'#ffffff',color:'black',gap:"10px",display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px" }}
          onClick={renderProps.onClick}
        >
          <BsFacebook style={{ width: '25px', height: '25px', color:" #3b5998" }} />
          <span>Continue with Facebook</span>
        </button>
        </div>
      )}
    />
{/* <LoginSocialFacebook
            appId='3122858851340111'
            autoLoad={false}
            fields="name,email,picture"
            callback={handleFacebookLogin}
            cssClass="btn btn-normal2 facebook m-0 border30 w-100 text-white d-flex align-items-center justify-content-center"
            render={renderProps => (
              <button
                style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px', border: '1px solid black',backgroundColor:'#ffffff',color:'black' }}
                onClick={renderProps.onClick}
              >
                <BsFacebook style={{ width: '25px', height: '25px' }} />
                Continue with Facebook
              </button>
            )}
          /> */}
    {/* <LoginSocialFacebook
      appId="598704202205918"
      onResolve={(response)=>{
        console.log('response', response)
      }}
      onReject={(error)=>{
        console.log('error message', error)
      }}
      fields="name,email,picture"
      // onResolve={({ provider, data }: IResolveParams) => {
      //   console.log('provider', provider)
      //   console.log('data', data)
      //   setProvider(provider);
      //   setProfile(data);
      // }}
      // onReject={err => {
      //   console.log(err);
      // }}
      >
         <Button variant='' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', height: '50px', border: '1px solid black' }}><BsFacebook style={{ width: '50px', height: '25px' }} />Continue With Facebook</Button> 
    </LoginSocialFacebook> */}
          
            
            <Button variant='' className='logingoogle' style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black',marginBottom:"25px" }}><BsApple style={{ width: '50px', height: '25px' }} />Continue With Apple</Button>
            {/* <button className='logingoogle' onClick={handleClick} style={{ fontWeight: '600', borderRadius: '20px', width: '70%', border: '1px solid black' }}>
        <AppleLogin
          clientId="W6RVM5DM47"
          // redirectURI="your-redirect-uri"
          responseType="code"
          usePopup={true}
          buttonText={<><BsApple style={{ width: '50px', height: '25px' }} />Continue With Apple</>}
        />
      </button> */}


{/* <AppleLogin
      clientId="D9FS8S7VMQ"
      redirectURI="https://calendar.specialdays.com/calendar"
      responseType="code"
      onSuccess={handleAppleLoginSuccess}
      onFailure={handleAppleLoginFailure}
      usePopup={true}
      buttonText="Sign in with Apple"
    /> */}

            
            
            <h6>By clicking Register,you agree to out</h6>
            <h6 style={{ color: '#FF3333',marginBottom:"20px" }}>Privacy policy & Terms of Use</h6>
          

            <h6>Not a Member ? <span style={{ color: '#FF3333', fontWeight: "600", cursor: "pointer" }} onClick={() => navigate("/signup")}> Sign Up here</span></h6>
          </Col>





          <Col className='homediv'>
            <img src={home} className='homeimg' />
          </Col>

        </Row>
      </Container>
    </div>



    // <div className='main'>
    //     <img src={specialdaylogo} className='speciallogo' />
    //     <div className='login'><br/><br/><br/><br/>

    //         <p className='logintext'>Please Login</p>
    //         <Input startDecorator={<CiMail/>} placeholder="Email" variant="soft" size='sm' style={{ height: 40, width: "440px" }} className='input' ></Input><br />
    //         <Input  startDecorator={<CiLock/>}  placeholder="Password" variant="soft" size='sm' style={{ height: 40, width: "440px" }} className='input' endDecorator={<BsEyeSlash/>}></Input>
    //         <p className='forgot' >Forgot Password?</p>
    //         <button className='loginbtn' onClick={()=>{navigate('/dashbord')}} >Log in</button>
    //         <h3 style={{ marginLeft: -120 }}>OR</h3>
    //         <button  className='loginbtngl'><FcGoogle className='google'/>Continue With Google</button><br/><br/>
    //         <button className='loginbtngl'><BsFacebook />Continue With Facebook</button><br/><br/>
    //         <button className='loginbtngl'><BsApple/>Continue With Apple</button><br/><br/>
    //         <h3 style={{ marginLeft:-120,fontWeight:300}} > By clickign Register.you agree to out</h3>
    //         <h3 style={{ marginLeft:-120,fontWeight:600}}  className='policy'>Privacy policy & Terms of Use</h3>
    //         <h3 style={{ marginLeft:-120,fontWeight:300}}  className='privacy'>Not a Member?<span className='here' onClick={()=>navigate("/signup")}>sign up here</span></h3>    
    //     </div>
    //     <div className='photo'>
    //         <img src={home} className='homeimg'/>
    //     </div>
    // </div>

  )
}

export default Login