import React, { useEffect, useRef, useState } from "react";
import user from "./assests/Ellipse.png";
import photo from "./assests/spd-logo-3 1.png";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import "./style/header.css";
import { useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
const Header = () => {
  const navigate = useNavigate();
  const [clickmenu, setclickmenu] = useState(true);
  const [loginornot, setloginornot] = useState(false);
  const menuRef = useRef(null);
  const menuclick = () => {
    setclickmenu(false);
    // menuRef.current.classList.toggle("menu_active");
    console.log("clickthai6");
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // if(!email){
  //   setloginornot(false);
  // }else{
  //   setloginornot(true);
  // }

  useEffect(() => {
    console.log("email", userInfo);
  }, []);

  return (
    <>
      {!clickmenu == true ? (
        <>
          <div
            ref={menuRef}
            className="main_nav_after_click_outside"
            onClick={() => {
              setclickmenu(true);
            }}
          >
            <div
              className="main_nav_after_click"
              style={{ backgroundColor: "#FFFFFF", height: "100%" }}
            >
              {/* <Row style={{ height: "120px" }} className="nav_main_under"> */}
              <div>
                <img style={{ width: "35%" }} src={photo}></img>
              </div>
              <div
                className="nav1"
                style={{ margin: "auto", color: "#FF3333" }}
              >
                <NavLink
                  to="/reminder"
                  style={{ textDecoration: "none", color: "black" }}
                  activeClassName="active"
                >
                  <h5
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Reminders
                  </h5>
                </NavLink>
              </div>
              <div className="nav1" style={{ margin: "auto" }}>
                <NavLink
                  to="/calendar"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Calender
                  </h5>
                </NavLink>
              </div>
              <div className="nav1" style={{ margin: "auto" }}>
                <NavLink
                  to="/discount"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Discounts
                  </h5>
                </NavLink>
              </div>
              <div className="nav1" style={{ margin: "auto" }}>
                <NavLink
                  to="/plan_a_party"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Plan a Party
                  </h5>
                </NavLink>
              </div>
              {!userInfo ? (
                ""
              ) : (
                <div className="nav1" style={{ margin: "auto" }}>
                  <div
                    onClick={() => {
                      navigate("/calendar");
                      localStorage.clear();
                    }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <h5
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                    >
                      Logout
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="main_nav"
            style={{ backgroundColor: "#FFFFFF", height: "100%" }}
          >
            <div className="menubtn_main">
              <HiMenu className="menubtn" onClick={menuclick} />
            </div>
            <Container className="nav_main">
              <Row
                style={{ height: "120px", marginLeft: "4px" }}
                className="nav_main_under"
              >
                <Col style={{ margin: "auto" }}>
                  <img
                    className="weblogo"
                    src={photo}
                    style={{ width: "80%" }}
                  ></img>
                </Col>

                {/* <Col
                  className="nav_main_username"
                  style={{ margin: "auto", display: "flex" }}
                >
                  <h5
                    className="nav_name"
                    style={{ fontWeight: "bold", margin: "auto" }}
                  >
                    Clay Spaidess
                  </h5>
                  <img className="userimg" src={user}></img>
                </Col> */}
                {!userInfo ? (
                  <Col className="lg1btn" style={{ margin: "auto -32px" }}>
                    <Button
                      className="logout_btn"
                      style={{
                        fontWeight: "600",
                        borderRadius: "25px",
                        width: "100px",
                        height: "50px",
                        backgroundColor: "#FF3333",
                        border: "none",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Log In
                    </Button>
                  </Col>
                ) : (
                  <>
                    <Col className="nav_main_username">
                      <Dropdown className="ddbtn_username_outside">
                        <Dropdown.Toggle
                          className="ddbtn_username"
                          id="dropdown-basic"
                        >
                          <h5
                            className="nav_name"
                            style={{ fontWeight: "bold", margin: "auto" }}
                          >
                                                   {userInfo?.firstName || userInfo?.givenName || userInfo?.data?.data?.firstName} {userInfo?.lastName || userInfo?.familyName || userInfo?.data?.data?.lastName}

                          </h5>
                          <img
                            className="userimg"
                            src={userInfo?.image || user}
                          ></img>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dpditem1">
                          <Dropdown.Item className="dpditem_username">
                          {userInfo?.firstName || userInfo?.givenName || userInfo?.data?.data?.firstName} {userInfo?.lastName || userInfo?.familyName || userInfo?.data?.data?.lastName}

                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dpditem"
                            href="#"
                            onClick={() => {
                              navigate("/calendar");
                              localStorage.clear();
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    {/* <h5
                      className="nav_name"
                      style={{ fontWeight: "bold", margin: "auto" }}
                    >
                      {userInfo?.firstName || userInfo?.givenName || userInfo}{" "}
                      {userInfo?.lastName || userInfo?.familyName}
                    </h5>
                    <img
                      className="userimg"
                      src={userInfo?.image || user}
                    ></img> */}
                    {/* <Col className="lg1btn pe-0" style={{ margin: "auto" }}>
                    <Button
                      className="logout_btn"
                      style={{
                        fontWeight: "600",
                        borderRadius: "25px",
                        width: "100px",
                        height: "50px",
                        backgroundColor: "#FF3333",
                        border: "none",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() => {
                        navigate("/calendar");
                        localStorage.clear();
                      }}
                    >
                      Logout
                    </Button>
                  </Col> */}
                  </>
                )}
                {/* <Col>8</Col> */}
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <div
          className="main_nav"
          style={{ backgroundColor: "#FFFFFF", height: "100%" }}
        >
          <div className="menubtn_main">
            <HiMenu className="menubtn" onClick={menuclick} />
          </div>
          <Container className="nav_main px-0">
            <Row
              style={{ height: "120px", marginLeft: "4px" }}
              className="nav_main_under"
            >
              <Col className="placeoflogo" style={{ margin: "auto" }}>
                <img
                  className="weblogo"
                  src={photo}
                  style={{ width: "80%" }}
                ></img>
              </Col>
              <Col
                className="menu_after_nav"
                style={{ margin: "auto", color: "#FF3333" }}
              >
                <NavLink
                  to="/reminder"
                  style={{ textDecoration: "none", color: "black" }}
                  activeClassName="active"
                >
                  <h5
                    className="nav_name"
                    style={{ fontWeight: "600", marginBottom: "0px" }}
                  >
                    Reminders
                  </h5>
                </NavLink>
              </Col>
              <Col
                className="text-secondary menu_after_nav"
                style={{ margin: "auto" }}
              >
                <NavLink
                  to="/calendar"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    className="nav_name"
                    style={{ fontWeight: "600", marginBottom: "0px" }}
                  >
                    Calender
                  </h5>
                </NavLink>
              </Col>
              <Col
                className="text-secondary menu_after_nav"
                style={{ margin: "auto" }}
              >
                <NavLink
                  to="/discount"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    className="nav_name"
                    style={{ fontWeight: "600", marginBottom: "0px" }}
                  >
                    Discounts
                  </h5>
                </NavLink>
              </Col>
              <Col
                className="text-secondary menu_after_nav"
                style={{ margin: "auto" }}
              >
                <NavLink
                  to="/plan_a_party"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h5
                    className="nav_name"
                    style={{ fontWeight: "600", marginBottom: "0px" }}
                  >
                    Plan a Party
                  </h5>
                </NavLink>
              </Col>

              {!userInfo ? (
                <Col className="lg1btn" style={{ margin: "auto -32px" }}>
                  <Button
                    className="logout_btn"
                    style={{
                      fontWeight: "600",
                      borderRadius: "25px",
                      width: "100px",
                      height: "50px",
                      backgroundColor: "#FF3333",
                      border: "none",
                      color: "rgb(255, 255, 255)",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Log In
                  </Button>
                </Col>
              ) : (
                <>
                  <Col className="nav_main_username">
                    <Dropdown className="ddbtn_username_outside">
                      <Dropdown.Toggle
                        className="ddbtn_username"
                        id="dropdown-basic"
                      >
                        <h5
                          className="nav_name"
                          style={{ fontWeight: "bold", margin: "auto" }}
                        >
                                                 {userInfo?.firstName || userInfo?.givenName || userInfo?.data?.data?.firstName} {userInfo?.lastName || userInfo?.familyName || userInfo?.data?.data?.lastName}

                        </h5>
                        <img
                          className="userimg"
                          src={userInfo?.image || user}
                        ></img>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dpditem1">
                        <Dropdown.Item className="dpditem_username">
                        {userInfo?.firstName || userInfo?.givenName || userInfo?.data?.data?.firstName} {userInfo?.lastName || userInfo?.familyName || userInfo?.data?.data?.lastName}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dpditem"
                          href="#"
                          onClick={() => {
                            navigate("/calendar");
                            localStorage.clear();
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  {/* <h5
                      className="nav_name"
                      style={{ fontWeight: "bold", margin: "auto" }}
                    >
                      {userInfo?.firstName || userInfo?.givenName || userInfo}{" "}
                      {userInfo?.lastName || userInfo?.familyName}
                    </h5>
                    <img
                      className="userimg"
                      src={userInfo?.image || user}
                    ></img> */}
                  {/* <Col className="lg1btn pe-0" style={{ margin: "auto" }}>
                    <Button
                      className="logout_btn"
                      style={{
                        fontWeight: "600",
                        borderRadius: "25px",
                        width: "100px",
                        height: "50px",
                        backgroundColor: "#FF3333",
                        border: "none",
                        color: "rgb(255, 255, 255)",
                      }}
                      onClick={() => {
                        navigate("/calendar");
                        localStorage.clear();
                      }}
                    >
                      Logout
                    </Button>
                  </Col> */}
                </>
              )}

              {/* <Col>8</Col> */}
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default Header;