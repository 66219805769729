import React, { useEffect, useState } from 'react'
// import Header from '../Header'
import '../style/dashbord.css'
import photo from '../assests/spd-logo-3 1.png'
// import { Col, Container, Row } from 'reactstrap'
import user from '../assests/Ellipse.png'
import { Box } from '@mui/material'
import { FiAlertCircle } from 'react-icons/fi'
import { Input } from '@mui/joy'
import { BsSearch, BsPencil } from 'react-icons/bs'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { FaBirthdayCake } from 'react-icons/fa'
import { RiShareForwardLine } from 'react-icons/ri'
import { SlCalender } from 'react-icons/sl'
import { MdOutlineDeleteForever } from 'react-icons/md'
import InputLabel from '@mui/material/InputLabel';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { BiTime } from 'react-icons/bi'
import axios from 'axios'
import moment from 'moment'
import Header from '../Header'
import Footer from '../Footer'
import { Container, Row, Col } from 'react-bootstrap'
import icon from '../assests/🦆 icon _menu_.png'
import vector from '../assests/Vector.png'
import calenderovector from '../assests/calendervector.png'
import cakephoto from '../assests/cakeimg.png'
import plusimg from '../assests/plusimg.png'
import greencake from '../assests/greencake.png'
import bluecake from '../assests/bluecake.png'
import calender from '../assests/calender.png'
import time from '../assests/time.png'
const Dashbord = () => {
    const [age, setAge] = React.useState('');
    const [data, setData] = useState([]);
    const [totalpage, settotalpage] = useState(0);
    const [currentpage, setcurrentpage] = useState(1);
    const [pagesize, setpagesize] = useState(10);
    const [searching, setsearching] = useState("");
    const [serch, setSerch] = useState('')
    const [edit, setEdit] = useState({})
    let today = moment().format('YYYY-MM-DD')
    const Body = {

        "search": serch,
        "limit": pagesize,
        "page": currentpage

    };

    useEffect(() => {
        axios.post('https://api.app.specialdays.com/user/get/reminder', Body)
            .then(res => {
                console.log("res", res?.data?.data?.page_data?.limit)

                setData(res?.data?.data?.response);
                settotalpage(res?.data?.data?.page_data?.page_limit)
            })
            .catch(error => {
                console.error(error);
            });
    }, [serch, currentpage, pagesize]);
    const handleChange = (e, i) => {
        setcurrentpage(i)
    };
    const handleonchnagespagination = (e) => {
        setpagesize(e.target.value)
    }
    const handleedit = (item) => {
        setEdit(item)
        console.log('item :>> ', item);
    }
    //   console.log('data :>> ', data);

    return (

        <div style={{ backgroundImage: '#F1F5FA', height: '100%' }}>
            <Header />

            <Box className='box'>
                <div className='firstdiv'>
                    <br /><br /><br />
                    <span style={{ fontWeight: 400 }}>Upcoming Birthday</span><br /><br />
                    <span style={{ fontWeight: 400, marginLeft: "-110px" }}>Age</span>
                </div>

                <div className='seconddiv'>
                    <br /><br /><br />
                    <span style={{ fontWeight: 600 }}>Jack Smith</span><br /><br />
                    <span style={{ fontWeight: 600, marginRight: '-20px ' }}>5y old</span>
                </div>

                <div className='seconddiv'>
                    <br /><br /><br />
                    <span style={{ fontWeight: 400 }}>Date of event</span><br /><br />
                    <span style={{ fontWeight: 400, marginLeft: '-20px' }}>Remaining</span>
                </div>

                <div className='seconddiv'>
                    <br /><br /><br />
                    <span style={{ fontWeight: 600 }}>3 Fab.2023</span><br /><br />
                    <span style={{ fontWeight: 600, marginRight: '-10px' }}>215 Days</span>
                </div>
            </Box>


            <Box className='box2'>

                <div className='box2first'><br /><br />
                    <div className='logo'><FiAlertCircle className='alertlogo' /></div> <span style={{ fontWeight: 600 }}> Profile Incomplete</span><br />

                </div>
                <div className='box2second'>
                    <span style={{ fontWeight: 400, color: "#000000" }}>Please update now so your friend and family can be reminder about your kry dates and status.</span>
                    <br /><br />  <hr />
                </div>
                <div className='box2third'><br /><br />
                    <span style={{ fontWeight: 400, color: "#000000" }}>Please update your First and Last name</span><br />

                </div>
            </Box>

            <br />
            <Container>
                <Row>
                    <Col >
                        <Input startDecorator={<BsSearch />} type='text' placeholder='Search your event' onChange={(e) => { setSerch(e.target.value); setcurrentpage(1) }} style={{ borderRadius: '20px', height: '50px' }}  ></Input>
                    </Col>
                    <Col xs lg="1" style={{ borderRadius: '20px' }}>
                        <select className='calenderdropdown'
                            onChange={(e) => handleonchnagespagination(e)}
                            value={pagesize}
                        >

                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                            <option value={70}>70</option>
                        </select>
                    </Col>
                </Row>
            </Container><br />
            {/* <Box>
                    <div className='serchbox'>
                        <Input startDecorator={<BsSearch />} type='text' placeholder='Search your event' className='serch' onChange={(e) => { setSerch(e.target.value); setcurrentpage(1) }} ></Input>

                        <select
                            class="dropdown"

                            onChange={(e) => handleonchnagespagination(e)}
                            value={pagesize}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                            <option value={70}>70</option>


                        </select>

                     
                    </div>
                </Box><br /><br /> */}


            <Container>
                {data?.map((item, index) => {

                    let fDate = moment(new Date(item?.eventDate)).format("YYYY-MM-DD")
                    let nDate = new Date().getFullYear() + 1
                    let futureDate = moment(new Date(`${nDate}-${fDate?.split("-")[1]}-${fDate?.split("-")[2]}`)).format("YYYY-MM-DD")

                    let days = Math.round(new Date(futureDate) - new Date(today) / (1000 * 60 * 60 * 24))
                    let Bday = moment(futureDate).diff(moment(new Date()), "days")

                    return (
                        <>

                            <Row>

                                <Col>
                                    <Row style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', height: '90px' }} className='align-items-center'>

                                        <Col className='col text-sm-end' xs="auto" md={4} style={{ marginLeft: "10px" }}>
                                            <Row className='align-items-center'>
                                                <Col className='d-flex text-start align-items-center'>
                                                    <div className="">
                                                        <img src={cakephoto} className="w-5" style={{ width: '35px' }}></img>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span style={{ fontSize: '1rem', fontWeight: '600', marginTop: '15px' }}>Birthday Event</span><br />
                                                        <span style={{}}>Birthday .{item.firstName} {item.lastName} . {item.relationship}</span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>

                                        <Col className='col text-sm-end' xs="auto" md={2} style={{ marginLeft: "10px" }}>
                                            <Row className='align-items-center'>
                                                <Col className='d-flex text-start align-items-center'>
                                                    <div className="">
                                                        <img src={calender} className="w-5" style={{ width: '35px' }}></img>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span style={{ fontSize: '1rem', fontWeight: '400', marginTop: '15px' }}>Date</span><br />
                                                        <span style={{ fontWeight: '600' }}>{moment(futureDate).format("DD MMM,YYYY").replace('.', '')}</span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>


                                        <Col className='col text-sm-end' xs="auto" md={2} style={{ marginLeft: "10px" }}>
                                            <Row className='align-items-center'>
                                                <Col className='d-flex text-start align-items-center'>
                                                    <div className="">
                                                        <img src={time} className="w-5" style={{ width: '35px' }}></img>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span style={{ fontSize: '1rem', fontWeight: '400', marginTop: '15px' }}>In</span><br />
                                                        <span style={{ fontWeight: '600' }}>{Bday} Days</span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>

                                        <Col style={{ marginRight: '0px' }} md={3}>
                                            <div style={{ backgroundColor: '#E5E5E5', borderRadius: '10px', height: '30px', margin: "auto" }}>
                                                <BsPencil style={{ color: 'red' }} /> <span className='edit' style={{ color: 'red', margin: "auto" }}> Edit </span>
                                                <RiShareForwardLine style={{ color: 'red' }} /> <span className='edit' style={{ color: 'red' }}>Share</span>
                                                <MdOutlineDeleteForever style={{ color: 'red' }} /><span className='edit' style={{ color: 'red' }}>Delete</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )
                })}
            </Container >

            {/* <Box className='datatable'>
                    <br />
                    {data?.map((item, index) => {

                        let fDate = moment(new Date(item?.eventDate)).format("YYYY-MM-DD")
                        let nDate = new Date().getFullYear() + 1
                        let futureDate = moment(new Date(`${nDate}-${fDate?.split("-")[1]}-${fDate?.split("-")[2]}`)).format("YYYY-MM-DD")

                        let days = Math.round(new Date(futureDate) - new Date(today) / (1000 * 60 * 60 * 24))
                        let Bday = moment(futureDate).diff(moment(new Date()), "days")

                        return (
                            <>
                               
                <Row>

                    <Col>
                        <Row style={{ backgroundColor: '#FFFFFF', borderRadius: '10px', height: '90px' }} className='align-items-center'>

                            <Col className='col text-sm-end' xs="auto" md={4} style={{ marginLeft: "10px" }}>
                                <Row className='align-items-center'>
                                    <Col className='d-flex text-start align-items-center'>
                                        <div className="">
                                            <img src={cakephoto} className="w-5" style={{ width: '35px' }}></img>
                                        </div>
                                        <div className="ms-3">
                                            <span style={{ fontSize: '1rem', fontWeight: '400', marginTop: '15px' }}>Birthday Event</span><br />
                                            <span style={{}}>Birthday . jack smith . Brother</span>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>

                            <Col className='col text-sm-end' xs="auto" md={2} style={{ marginLeft: "10px" }}>
                                <Row className='align-items-center'>
                                    <Col className='d-flex text-start align-items-center'>
                                        <div className="">
                                            <img src={calender} className="w-5" style={{ width: '35px' }}></img>
                                        </div>
                                        <div className="ms-3">
                                            <span style={{ fontSize: '1rem', fontWeight: '400', marginTop: '15px' }}>Date</span><br />
                                            <span style={{}}>16 Dec,2022</span>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>


                            <Col className='col text-sm-end' xs="auto" md={2} style={{ marginLeft: "10px" }}>
                                <Row className='align-items-center'>
                                    <Col className='d-flex text-start align-items-center'>
                                        <div className="">
                                            <img src={time} className="w-5" style={{ width: '35px' }}></img>
                                        </div>
                                        <div className="ms-3">
                                            <span style={{ fontSize: '1rem', fontWeight: '400', marginTop: '15px' }}>In</span><br />
                                            <span style={{}}>265 Days</span>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>

                            <Col style={{ marginRight: '0px' }} md={3}>
                                <div >
                                    <BsPencil /> <span className='edit' > Edit</span>
                                    <RiShareForwardLine /> <span className='edit'>Share</span>
                                    <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                            </>
                        )
                    })} */}

            {/* <Row className='row'>
                        <Col className='data1' style={{ fontweight: 600 }}>

                            <FaBirthdayCake className='cake'  />Birthday Event<br />
                            <span className='info'>Birthday . jack smith . Brother</span></Col>
                        <Col className='data2' style={{ fontweight: 400 }}> <SlCalender className='cake' />Date<br /><span className='info2'>16 Dec,2022</span></Col>
                        <Col className='data3' style={{ fontweight: 600 }}> <BiTime className='cake' />In<br /><span className='info3'>265 Days</span></Col>
                        <div className='editsection'>
                            <BsPencil /> <span className='edit'> Edit</span>
                            <RiShareForwardLine /> <span className='edit'>Share</span>
                            <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                        </div>
                    </Row><br /><br /><hr/> */}

            {/* <Row className='row'>
                        <Col className='data1' style={{ fontweight: 600 }}><FaBirthdayCake className='cake' />Birthday Event<br /><span className='info'>Birthday . jack smith . Brother</span></Col>
                        <Col className='data2' style={{ fontweight: 600 }}> <SlCalender className='cake' />Date<br /><span className='info2'>16 Dec,2022</span></Col>
                        <Col className='data3' style={{ fontweight: 600 }}> <BiTime className='cake' />In<br /><span className='info3'>265 Days</span></Col>
                        <div className='editsection'>
                            <BsPencil /> <span className='edit'> Edit</span>
                            <RiShareForwardLine /> <span className='edit'>Share</span>
                            <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                        </div>
                    </Row><br /><br /><hr/>


                    <Row className='row'>
                        <Col className='data1' style={{ fontweight: 600 }}><FaBirthdayCake className='cake' />Birthday Event<br /><span className='info'>Birthday . jack smith . Brother</span></Col>
                        <Col className='data2' style={{ fontweight: 600 }}> <SlCalender className='cake' />Date<br /><span className='info2'>16 Dec,2022</span></Col>
                        <Col className='data3' style={{ fontweight: 600 }}> <BiTime className='cake' />In<br /><span className='info3'>265 Days</span></Col>
                        <div className='editsection'>
                            <BsPencil /> <span className='edit'> Edit</span>
                            <RiShareForwardLine /> <span className='edit'>Share</span>
                            <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                        </div>
                    </Row><br /><br /><hr/>


                    <Row className='row'>
                        <Col className='data1' style={{ fontweight: 600 }}><FaBirthdayCake className='cake' />Birthday Event<br /><span className='info'>Birthday . jack smith . Brother</span></Col>
                        <Col className='data2' style={{ fontweight: 600 }}> <SlCalender className='cake' />Date<br /><span className='info2'>16 Dec,2022</span></Col>
                        <Col className='data3' style={{ fontweight: 600 }}> <BiTime className='cake' />In<br /><span className='info3'>265 Days</span></Col>
                        <div className='editsection'>
                            <BsPencil /> <span className='edit'> Edit</span>
                            <RiShareForwardLine /> <span className='edit'>Share</span>
                            <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                        </div>
                    </Row><br /><br /><hr/>



                    <Row className='row'>
                        <Col className='data1' style={{ fontweight: 600 }}><FaBirthdayCake className='cake' />Birthday Event<br /><span className='info'>Birthday . jack smith . Brother</span></Col>
                        <Col className='data2' style={{ fontweight: 600 }}> <SlCalender className='cake' />Date<br /><span className='info2'>16 Dec,2022</span></Col>
                        <Col className='data3' style={{ fontweight: 600 }}> <BiTime className='cake' />In<br /><span className='info3'>265 Days</span></Col>
                        <div className='editsection'>
                            <BsPencil /> <span className='edit'> Edit</span>
                            <RiShareForwardLine /> <span className='edit'>Share</span>
                            <MdOutlineDeleteForever /><span className='edit'>Delete</span>
                        </div>
                    </Row> */}

            {/* </Box> */}
            <br />
            <Container>
                <Row>
                    <Col>
                        <Box style={{ marginLeft: "500px" }}>
                            <Pagination
                                count={totalpage}
                                page={currentpage}
                                onChange={handleChange}
                            />
                        </Box>
                    </Col>
                </Row>
                <br />
                <Container>
                    <Row>
                        <Col>
                            <button style={{ color: '#ffffff', backgroundColor: '#FF3333', borderRadius: '10px', border: 'none', width: '90%', height: '60px', fontWeight: '600' }}>Add Reminders</button>

                        </Col>
                        <Col>
                            <button style={{ color: '#FF3333', backgroundColor: '#ffffff', borderRadius: '10px', border: 'none', width: '90%', height: '60px', fontWeight: '600', border: "1px solid #FF3333" }}>Add Holiday</button>

                        </Col>
                        <Col>
                            <button style={{ color: '#FF3333', backgroundColor: '#ffffff', borderRadius: '10px', border: 'none', width: '90%', height: '60px', fontWeight: '600', border: "1px solid #FF3333" }}>Invite a Friends</button>

                        </Col>

                    </Row>


                </Container>
            </Container>
            <br /><br /><br />
            <Footer />
            {/* <Box className='box3'>
                    <div className='footer'>
                        <img src={photo} className='footerlogo' />
                    </div>
                    <h4 className='footertext'>thank you for visiting our website </h4>
                    <div className='sevices'>
                        <h3 className='serviceheading'>Our services</h3>
                        <h4 style={{ color: '#454545', marginLeft: '90px', fontweight: '200 ' }}>How it works</h4>
                        <h4 style={{ color: '#454545', marginLeft: '110px' }}>Sign Up-It's FREE</h4>
                        <h4 style={{ color: '#454545', marginLeft: '160px' }}>Already a Member?Login</h4>
                    </div>

                    <div className='sevices'>
                        <h3 className='serviceheading'>Resources</h3>
                        <h4 style={{ color: '#454545', marginLeft: '140px', fontweight: '600' }}>Holiday Calender</h4>
                        <h4 style={{ color: '#454545', marginLeft: '100px' }}>Gift Guides</h4>
                        <h4 style={{ color: '#454545', marginLeft: '150px' }}>Special Days Blog</h4>
                    </div>

                    <div className='sevices'>
                        <h3 className='serviceheading'>Company</h3>
                        <h4 style={{ color: '#454545', marginLeft: '90px', fontweight: '600' }}>About Us</h4>
                        <h4 style={{ color: '#454545', marginLeft: '130px' }}>Partner With Us</h4>
                        <h4 style={{ color: '#454545', marginLeft: '100px' }}>Contact Us</h4>
                    </div>

                    <div className='sevices'>
                        <h3 className='serviceheading'>Support</h3>
                        <h4 style={{ color: '#454545', marginLeft: '120px', fontweight: '600' }}>Privacy Policy</h4>
                        <h4 style={{ color: '#454545', marginLeft: '140px' }}>Terms of Services</h4>
                        <h4 style={{ color: '#454545', marginLeft: '80px' }}>Cookies</h4>
                    </div>
                </Box> */}



        </div>


    )
}

export default Dashbord