import React from 'react'
import Header from '../Header'
import { Container, Row, Col } from 'react-bootstrap'
import { BsSearch, BsPencil } from 'react-icons/bs'
import { Input } from '@mui/joy'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Footer from '../Footer'
import Box from '@mui/material/Box';
import lv from '../assests/louis.png'
import burger from '../assests/Burger_King_1994_logo 2.png'
import lakme from '../assests/lakme.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import mask from '../assests/Mask group.png'
import vege from '../assests/vgetabel.png'
import run from '../assests/Easter Sunday.png'
import axios from 'axios'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import '../style/discount.css'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from '@mui/material/Skeleton';

import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
} from "mdb-react-ui-kit";

const Discount = () => {
    const navigate = useNavigate()
    const [value, setValue] = React.useState('one');
    const [data, setData] = useState([])
    const [pagesize, setpagesize] = useState(10);
    const [serch, setSerch] = useState('');
    const [tab, setTab] = useState([]);
    const [currentpage, setcurrentpage] = useState(1);
    const [category, setCategory] = useState("")
    const [card, setCard] = useState("")
    const [all, setAll] = useState([])
    const [alldata, setAlldata] = useState([])
    const [basicActive, setBasicActive] = useState("");
    const [extraflag, setextraflag] = useState(true)
    const [isClicked, setIsClicked] = useState(false);
    const [totalCount, setTotalCount] = useState("");

    // const loginornot = localStorage.getItem("loginornot");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));


    const Body = {
        "search": serch,
        "categoryName": category,
        "limit": pagesize,
        "page": currentpage
    }

    useEffect(() => {
        axios.post('https://api.app.specialdays.com/user/get/discount/without/tokon', Body)
            .then(res => {
                console.log("discount data", res?.data?.data?.response)

                setData(res?.data?.data?.response);

            })
            .catch(error => {
                console.error(error);
            });
    }, [serch, pagesize, category, currentpage]);
    console.log('category===', category)
    // console.log('tab  data:>> ', tab);
    useEffect(() => {
        const a = [];
        axios.get('https://api.app.specialdays.com/user/get/discount/category/without/tokon')
            .then(res => {

                console.log("tabdata", res)
                setTab(res?.data?.data?.data);
                setAll(res?.data?.data?.totalrecord)
                a.push(
                    res?.data?.data?.data.map((res, index) => {
                        return res.totalCount;
                    })
                );
                let sum = a[0].reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                );
                setTotalCount(sum);

            })
            .catch(error => {
                console.error(error);
            });
    }, [serch, currentpage, pagesize]);

    const handleonchnagespagination = (e) => {
        setpagesize(e.target.value)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const handletab = (i) => {
    //     console.log('i :>> ', i);
    //     setCategory(i)
    // }
    // const handleall=(i)=>{
    //     setCategory(i)
    // }
    const handletab = (i, index) => {
        setextraflag(index);
        console.log("i :>> ", i);
        setCategory(i);
        setIsClicked(!isClicked);
        setBasicActive(index);
        // setIsClickedleft(true);
    };
    const handleall = (i) => {
        setBasicActive("");
        setCategory(i);
        console.log("juhil", i);
    };



    const handlecarddetails = (i, e) => {
        if (!userInfo) {
            if (i.isLoginRequired == true) {
                console.log('firs login')
                navigate('/login')
            }
            else {
                console.log('second login false require')
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth" // Add smooth scrolling effect
                });
                setCard(i)
                // console.log('ilov', e)
                navigate(`/fashion?itemStatus=${JSON.stringify(i?.isOnline)}&pageSize=${e}&category=${category}&search=${serch||""}`, { state: { i, e, category, serch } })
            }
        } else {
            if (i.isLoginRequired == true) {
                console.log('firs login')
                navigate(`/fashion?itemStatus=${JSON.stringify(i?.isOnline)}&pageSize=${e}&category=${category}&search=${serch||""}`, { state: { i, e, category, serch } })
            }
            else {
                console.log('second login false require')
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth" // Add smooth scrolling effect
                });
                setCard(i)
                // navigate("/fashion", { state: { i, e, category, serch } })
                navigate(`/fashion?itemStatus=${JSON.stringify(i?.isOnline)}&pageSize=${e}&category=${category}&search=${serch||""}`, { state: { i, e, category, serch } })
            }
        }

    }
    console.log('card :>> ', card);

    // const handlecarddetails=()=>{
    //     console.log('object :>>  );
    // }
    return (
        <div style={{ backgroundColor: ' #F1F5FA', height: '100%' }}>
            <Header />
            <br />
            <br />
            <Container>
                <Row>
                    <Col>
                        <h1 className='headingcalender' style={{fontSize: '3rem', fontWeight: 'bold' }}>Partner Discounts</h1><br/>
                        <div style={{   fontSize: "1.1rem",fontWeight: "600"}}>Make more of your Special Days by taking advantage of some of our partner discounts to help with buying gifts and planning for any Special Day. Create an account to gain access to some of our exclusive discounts and features only available to members.</div>
                    </Col>
                </Row>

                <br />
            <br />
                {/* <Row style={{ margin: "auto", height: '300px', borderRadius: '20px', backgroundColor: '#ffffff' }} >
                    <Col  xs={6}  style={{ margin: 'auto', backgroundColor: '#ffffff' }}><h1 style={{ fontWeight: '600' }}>Rotating banner</h1> </Col>
                    <Col   xs={6}  style={{ backgroundColor: '#ff3333', borderRadius: '20px' }}></Col>

                </Row> */}
                <br />
                <Row id="sele" className="d-flex justify-content-center serchbardiscount_main">
                    <Col lg={10} sm={6} className='serchbardiscount'>
                        <Input
                            startDecorator={<BsSearch style={{ marginLeft: '20px' }} />}
                            type="text"
                            placeholder="Search your event"
                            className='serchbar'
                            onChange={(e) => {
                                setSerch(e.target.value);
                            }}
                            style={{ borderRadius: "40px", height: "50px" }}
                        ></Input>
                    </Col>
                    <Col className="selecbtnshow  dropdown" id="selectshow" lg={2} sm={6} style={{ borderRadius: '25px' }}>
                        Show
                        <select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            // className="textfieldinput"
                            className='selectcountry'
                            onChange={(e) => handleonchnagespagination(e)}
                            value={pagesize}
                            style={{ backgroundColor: '#FF3333', color: '#ffffff', border: '1px solid #FF3333', outline: 'none', width: '120px' }}
                        >
                            {/* <MenuItem value="Gender">Gender</MenuItem> */}
                            <option style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000' }} className='all' value={10}>10</option>
                            <option style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000', width: '50px' }} value={30}>30</option>
                            <option style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000', width: '50px' }} value={50}>50</option>
                            <option style={{ border: 'none', backgroundColor: '#ffffff', color: '#000000', width: '50px' }} value={70}>70</option>
                        </select>
                    </Col>
                </Row>
                {/* 
                <Row>
                    <Col >
                        <Input startDecorator={<BsSearch />} type='text' placeholder='Search your event' style={{ borderRadius: '20px' }} onChange={(e) => setSerch(e.target.value)}></Input>
                    </Col>
                    <Col xs lg="1" style={{ borderRadius: '20px' }}>
                        <select className='calenderdropdown'
                            onChange={(e) => handleonchnagespagination(e)}
                            value={pagesize}
                        >
                            
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                            <option value={70}>70</option>
                        </select>
                    </Col>
                </Row> */}
                <br />

                <Row id='tab'>
                    <Col>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <MDBTabs className="mb-3">
                                    <MDBTabsItem>
                                        <MDBTabsLink
                                            className={basicActive === "" ? "nav-linkactive" : ""}
                                            onClick={(e) => handleall("")}
                                        // active={basicActive === tabs.categoryName}
                                        >
                                            <div
                                                className={
                                                    basicActive === "" ? "spantxtactive" : "spantxt"
                                                }
                                            >
                                                All
                                            </div>
                                            <div
                                                className={totalCount == 0 ? ('') : (
                                                    basicActive === "" ? "spannumactive" : "spannum")
                                                }
                                            >
                                                {totalCount}
                                            </div>
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                </MDBTabs>

                                {tab.length > 0 ? (
                                    tab.map((tabs, index) => (
                                        <React.Fragment key={tabs.categoryName}>
                                            <MDBTabs className="mb-3">
                                                <MDBTabsItem>
                                                    <MDBTabsLink
                                                        className={
                                                            basicActive === index ? "nav-linkactive" : ""
                                                        }
                                                        onClick={() => handletab(tabs.categoryName, index)}
                                                    >
                                                        <div
                                                            className={
                                                                basicActive === index
                                                                    ? "spantxtactive"
                                                                    : "spantxt"
                                                            }
                                                        >
                                                            {tabs.categoryName}
                                                        </div>
                                                        <div
                                                            className={
                                                                basicActive === index
                                                                    ? "spannumactive"
                                                                    : "spannum"
                                                            }
                                                        >
                                                            {tabs.totalCount}
                                                        </div>
                                                    </MDBTabsLink>
                                                </MDBTabsItem>
                                            </MDBTabs>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <React.Fragment>
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />
                                        <Skeleton variant="rectangular" width="50%" height={40} />

                                    </React.Fragment>
                                )}

                            </Tabs>
                        </Box>
                    </Col>
                </Row>
                <br /><br />

                <Row >
                    {data.length !== 0 ? (data.map((item, index) => {
                        return (
                            <>
                                <Col lg={4} style={{ padding: "20px" }} onClick={(e) => handlecarddetails(item, index + 1)}>
                                    <Card sx={{ maxWidth: 700, marginLeft: '450px', borderRadius: '10px', margin: 'auto' }} style={{ cursor: 'pointer' }}>
                                        {/* <CardActionArea className='cardhover' style={{ cursor: 'pointer' }}> */}
                                            <CardMedia
                                                component="img"
                                                height="auto"
                                                image={mask}
                                                alt="green iguana"
                                            /><br />
                                            <Row style={{ position: "relative" }}>
                                                <Col
                                                    style={{
                                                        paddingTop: "20px",
                                                        backgroundColor: " white",
                                                        position: "relative",
                                                        top: "-60px",
                                                        borderRadius: "50px  50px 0px 0px ",
                                                    }}
                                                >
                                                    <div>

                                                        <div>
                                                            <div className="w-100 position-absolute z-2" style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: "20px", marginBottom: "50px" }} >
                                                                <hr className="w-100" style={{ border: "1px solid #0000001f" }} />
                                                            </div>
                                                            <span style={{ position: "-webkit-sticky", position: "sticky", zIndex: "3" }} >
                                                                <img src={`https://special-days.s3.amazonaws.com/${item.image}`} className='dis_img' style={{ height: '71px' , backgroundColor:"white" }}></img></span></div>

                                                        <h3 style={{ fontWeight: '600', padding: '10px', fontSize: "24px", marginBottom: "0px", marginTop: "0px", paddingBottom: "0px" }}>{item.name}</h3>
                                                        <h3 style={{ color: '#ED2225', padding: '10px', fontWeight: '600', fontSize: "24px", marginBottom: "0px", paddingBottom: "0px" }}>{item.discount} Discount</h3>
                                                    </div>
                                                    <h4 style={{ padding: '10px', fontWeight: '600', fontSize: "25px", marginBottom: "0px", paddingBottom: "0px" }}>{item.isOnline === true ? `Online ` : `Ofline`}</h4>
                                                    <h4 style={{ padding: '10px', fontWeight: '600', fontSize: "18px", marginBottom: "0px", paddingBottom: "0px" }}>EXP : {moment(item.expDate).utc().format("DD MMM,YYYY")} </h4>


                                                </Col>
                                            </Row>
                                        {/* </CardActionArea> */}
                                    </Card>
                                </Col>



                                {/* 



                    <Col>
                        <Card sx={{ maxWidth: 700, marginLeft: '450px', borderRadius: '10px', margin: 'auto' }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="222.77px"
                                    image={vege}
                                    alt="green iguana"
                                /><br />
                                <Row>
                                    <Col>
                                        <img src={burger} style={{ height: '71px' }}></img>
                                        <h3>Burger King</h3>
                                        <h3 style={{ color: '#ED2225' }}>20% Discount</h3>
                                        <h4>Online</h4>
                                        <h4>EXP : 20 Dec,2023</h4>


                                    </Col>
                                </Row>
                            </CardActionArea>
                        </Card></Col>



                    <Col><Card sx={{ maxWidth: 700, marginLeft: '450px', borderRadius: '10px', margin: 'auto' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="auto"
                                image={run}
                                alt="green iguana"
                            /><br />
                            <Row>
                                <Col>
                                    <img src={lakme}></img>
                                    <h3>Lakme</h3>
                                    <h3 style={{ color: '#ED2225' }}>10% Discount</h3>
                                    <h4>Online</h4>
                                    <h4>EXP : 20 Dec,2023</h4>


                                </Col>
                            </Row>
                        </CardActionArea>                 
                    </Card></Col> */}


                            </>

                        )
                    })) : (<h1>No data Found</h1>)}
                </Row >



                <br /><br />
                {data.length !== 0 ? (<Row>
                    <Col xs={12} style={{ margin: 'auto' }}>
                        <button className='loadbtn' style={{ color: '#ffffff', backgroundColor: '#FF3333', borderRadius: '35px', border: 'none', width: '30%', height: '60px', fontWeight: '600', padding: '10px' }}>Load more discounts</button>
                    </Col>

                </Row>) : ('')}


            </Container><br /><br />
            <Footer />
        </div >
    )
}

export default Discount