import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Container, Row, Col } from "react-bootstrap";
import { CardActionArea } from "@mui/material";
import calendervector from "../assests/calendervector.png";
import plus from "../assests/plus.png";
import gray from "../assests/grayback.png";
import red from "../assests/red.png";
import main from "../assests/Mask group.png";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import white from "../assests/white.png";
// import black from "../assests/black.jpg";
import lv from "../assests/louis.png";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import ".././style/fashion.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SuccessToast, ErrorToast } from "../helper/Toast";
import { Box } from "@mui/joy";

const Fashion = () => {
  const location = useLocation();
  let params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [item, setItem] = useState("");
  const [data, setData] = useState([]);
  const [serch, setSerch] = useState("");
  const [category, setCategory] = useState(params.get("category"));
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(Number(params.get("pageSize")));
  const [limit, setLimit] = useState("");
  const [sercha, setSercha] = useState(params.get("search"));
  const [discount,setDiscount] =useState('')


  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  console.log("limit :>> ", limit);
  console.log('params', item)
  console.log('data', data)
  const Body = {
    search: sercha,
    categoryName: category,
    limit: 1,
    page: pagesize,
  };
  useEffect(() => {
    setItem(Boolean(params.get("itemStatus")));
  }, []);
  console.log(location?.state?.category);
  useEffect(() => {
    axios
      .post(
        "https://api.app.specialdays.com/user/get/discount/without/tokon",
        Body
      )
      .then((res) => {
        console.log("discountdata", res?.data?.data?.response[0]?.isLoginRequired);
        setLimit(res?.data?.data?.page_data?.page_limit);
        if( !userInfo && res?.data?.data?.response[0]?.isLoginRequired == true){

          navigate("/login")
        }else{
          setData(res?.data?.data?.response[0]);
      }
        setDiscount( res?.data?.data?.response[0]?.isLoginRequired)
        
        // if(res?.data?.data?.response[0]?.isLoginRequired){
        //   navigate('/login')
        // }else{
          
        //   setLimit(res?.data?.data?.page_data?.page_limit);
        //   setData(res?.data?.data?.response[0]);
        //   setDiscount( res?.data?.data?.response[0]?.isLoginRequired)
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pagesize]);
  console.log("data :>> ", data?.isLoginRequired);
  // console.log('item :>> ', item);
  // console.log('index :>> ', location.state.e);
  console.log("link", data?.link);
  const handalleft = () => {
    if (pagesize <= 1 ) {
      // alert("no data found")
      // ErrorToast("data no found !", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      console.log("object minus:>> ");
    } else {
      setpagesize(pagesize - 1);
      navigate(`/fashion?itemStatus=${item}&pageSize=${pagesize - 1}&category=${category}&search=${sercha}`)
      // if( data.isLoginRequired == false){
      //   navigate('/login')
      // }
    }
    console.log("handleleft :>>");
  };
  const handalright = () => {
    console.log("object minus:>> ");
    console.log("object minus:>> ", data);
    if (pagesize == limit ) {
      // ErrorToast("no data found");
    } else {
      setpagesize(pagesize + 1);
      navigate(`/fashion?itemStatus=${item}&pageSize=${pagesize + 1}&category=${category}&search=${sercha}`)
      console.log("handleright :>> ");
    }
  };
  const buttonhandler = () => {
    console.log("button click");
  };
  let date = moment(data?.expDate).format("DD MMM,YYYY");
  return (
    <div style={{ backgroundColor: "#F1F5FA", height: "100%" }}>
      <Header />
      <br />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Row style={{ display: "flex" }}>
          <Col style={{ display: "flex" }}>
            <h6
              style={{ cursor: "pointer", fontWeight: "600" }}
              onClick={() => navigate("/discount")}
            >
              Home <MdOutlineArrowForwardIos fontSize={12} /> {data?.name}
            </h6>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <h1 style={{ fontWeight: "600" }}>{data?.name}</h1>
          </Col>
        </Row>
        <br />
        <Row className="card_main" style={{ borderRadius: "10px" }}>
          <Col>
            <Card
              sx={{
                maxWidth: 700,
                marginLeft: "450px",
                borderRadius: "25px",
                margin: "auto",
              }}
            >
              {/* <CardActionArea style={{ alignItems: "center" }}> */}
                {/* <div
                  style={{
                    float: "left",
                    color: "white",
                    position: "relative",
                    top: "400px",
                    left: "100px",
                  }}
                >
                  <img
                    className="fashionimg"
                    src={`https://special-days.s3.amazonaws.com/${data.image}`}
                    style={{
                      height: "50px",
                      marginTop: "30px",
                      marginLeft: "30px",
                      alignItems: "center",
                    }}
                  ></img>
                </div> */}
                {/* <img src={white} style={{backgroundColor:'black',width:'70px',height:'90px',float:'left',marginTop:'60px'}}></img> */}
                {/* <div className='logoimg'>
                        <img src={`https://special-days.s3.amazonaws.com/${data.image}`} ></img>
                </div> */}
                <CardMedia
                  component="img"
                  height="100%"
                  image={main}
                  alt="green iguana"
                />
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="middel_imgbg"
                    // src={black}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "150px",
                      height: "100px",
                      position: "relative",
                      padding: "20px",
                      top: "-100px",
                      // left: "280px",
                      background: "white",
                    }}
                  >
                    <div className="logoimg">
                      <img
                        className="middel_img"
                        // style={{ width: "70px", height: "50px" }}
                        style={{ width: "100px", height: "70px" }}
                        src={`https://special-days.s3.amazonaws.com/${data?.image}`}
                      ></img>
                    </div>
                  </div>
                  <Col
                    style={{
                      marginTop: "-70px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 style={{ color: "#ED2225", fontWeight: "600" ,fontSize:"30px" }}>
                      {data?.discount} Discount
                    </h1>
                    <h4 style={{ fontWeight: "600" ,fontSize:"25px", marginBottom:"0px" }}>
                      {item === true ? `Online ` : `Offline`}
                    </h4>
                    <div className="w-100" style={{paddingLeft:"50px" , paddingRight:"50px", marginTop:"-10px"}} >
                    <hr className="w-100" style={{border:"1px solid #0000001f"}} />
                    </div>
                    <h4 style={{ fontWeight: "600",fontSize:"18px", marginTop:"-5px" }}>
                      Exp : {moment(data?.expDate).utc().format("DD MMM,YYYY")}{" "}
                    </h4>
                    <br />
                    <Box>
                      <h6
                        style={{
                          textAlign: "justify",
                          marginRight: "58px",
                          marginLeft: "41px",
                        }}
                      >
                        {data?.description}
                      </h6>
                    </Box>
                    <br />
                    <a
                      className="btn claimbtn"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#FF3333",
                        borderRadius: "25px",
                        border: "none",
                        width: "300px",
                        height: "40px",
                        marginBottom: "50px",
                      }}
                      href={data?.link}
                      target="_blank"
                    >
                      Claim This Deal
                    </a>
                  </Col>
                </div>
              {/* </CardActionArea> */}
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // width: "200px",
            // maxWidth: 700,
            // marginLeft: "450px",
            // borderRadius: "10px",
            // margin: "auto",
          }}
        >
          <Col>
            {/* <img onClick={handalleft} src={gray} /> */}
            <button
              onClick={handalleft}
              className={pagesize === 1 ? "disablefashion" : "clickedleft"}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50px",
                border: "none",
                cursor: "pointer",
              }}
            >
              <IoIosArrowBack />
            </button>
          </Col>
          {/* <Col md={8} style={{ margin: 'auto' }}><button style={{ color: '#FFFFFF', backgroundColor: '#FF3333', borderRadius: '20px', border: 'none', width: '60%', height: '50px' }} onClick={()=>{navigate("/calender")}}>See all events</button></Col> */}
          <Col>
            {/* <img onClick={handalright} src={red} /> */}
            <button
              onClick={handalright}
              className={pagesize === limit ? "disablefashion" : "clicked"}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50px",
                border: "none",
                cursor: "pointer",
              }}
            >
              <IoIosArrowForward />
            </button>
          </Col>
          {/* <Col md={2} onClick={handalleft}><img src={gray} /></Col>
                  <Col md={8} style={{ margin: 'auto' }}></Col>
                  <Col md={2} onClick={handalright}><img src={red} /></Col> */}
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
    </div>
  );
};
export default Fashion;