import React from 'react'
import photo from './assests/spd-logo-3 1.png'
import { Box } from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap'
import './style/footer.css'
const Footer = () => {
    return (
        <div style={{ backgroundColor: '#ffffff' }}>
            <Container >
                <Row >
                    <Col md={12} lg={4}><br/>
                        <img src={photo} style={{ width: '300px' }} className='footerimg'></img> 
                        <p className='text-secondary text-start'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, qui?Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                    </Col>

                    <Col className=' text-start text-secondary' xs={12} md={6} lg={2}><br/>
                        <h3 className='headings' style={{ color: '#FF3333', fontWeight: '600' }}>Our Services</h3>
                        <p  className='headings'  style={{ fontWeight: '600', }}>How it Works?</p>
                        <div> <p className='headings' style={{ fontWeight: '600',  }}>Sign Up-It's Free</p> </div>
                        <p className='headings' style={{ fontWeight: '600',  }}>Already a Member?-Login</p>

                    </Col>

                    <Col  className=' text-start text-secondary' xs={12} md={6} lg={2}><br/>
                        <h3  style={{ color: '#FF3333', fontWeight: '600', }}>Resources</h3>
                        <p style={{ fontWeight: '600', }}>Holiday Calenders</p>
                        <div> <p style={{ fontWeight: '600',  }}>Gift Guides</p> </div>
                        <p style={{ fontWeight: '600' }}>Speical Day Blog</p>
                    </Col>

                    <Col  className=' text-start text-secondary' xs={12} md={6} lg={2}><br/>
                        <h3 className='headings' style={{ color: '#FF3333', fontWeight: '600'}}>Company</h3>
                        <p className='headings' style={{ fontWeight: '600' }}>About Us</p>
                        <div> <p className='headings' style={{ fontWeight: '600' }}>Partner With Us</p> </div>
                        <p className='headings' style={{ fontWeight: '600' }}>Contact Us</p>
                    </Col>



                    <Col   className=' text-start text-secondary' xs={12} md={6} lg={2}><br/>
                        <h3 style={{ color: '#FF3333', fontWeight: '600'  }}>Support</h3>
                        <p style={{ fontWeight: '600', }}>Privacy Policy</p>
                        <div> <p style={{ fontWeight: '600',  }}>Term & Coditions</p> </div>
                        <p style={{ fontWeight: '600',  }}>Cookies</p>
                    </Col>

                </Row>

            </Container>
            <hr/>
            <div>
                <span style={{paddingBottom:'30px'}}>Copyright @ 2023 Special Days</span><br/><br/>
             
            </div>
        </div>
        //     <Box className='box3'>
        //     <div className='footer'>
        //         <img src={photo} className='footerlogo' />
        //     </div>
        //     <h4 className='footertext'>thank you for visiting our website </h4>
        //     <div className='sevices'>
        //         <h3 className='serviceheading'>Our services</h3>
        //         <h4 style={{ color: '#454545', marginLeft: '90px', fontweight: '200 ' }}>How it works</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '110px' }}>Sign Up-It's FREE</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '160px' }}>Already a Member?Login</h4>
        //     </div>

        //     <div className='sevices'>
        //         <h3 className='serviceheading'>Resources</h3>
        //         <h4 style={{ color: '#454545', marginLeft: '140px', fontweight: '600' }}>Holiday Calender</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '100px' }}>Gift Guides</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '150px' }}>Special Days Blog</h4>
        //     </div>

        //     <div className='sevices'>
        //         <h3 className='serviceheading'>Company</h3>
        //         <h4 style={{ color: '#454545', marginLeft: '90px', fontweight: '600' }}>About Us</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '130px' }}>Partner With Us</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '100px' }}>Contact Us</h4>
        //     </div>

        //     <div className='sevices'>
        //         <h3 className='serviceheading'>Support</h3>
        //         <h4 style={{ color: '#454545', marginLeft: '120px', fontweight: '600' }}>Privacy Policy</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '140px' }}>Terms of Services</h4>
        //         <h4 style={{ color: '#454545', marginLeft: '80px' }}>Cookies</h4>
        //     </div>
        // </Box>


    )
}

export default Footer