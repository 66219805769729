import React from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const Dashbord2 = () => {
  const navigate=useNavigate()
  return (
    <>

    <div style={{marginTop:"300px", fontSize:"30px"}}>Coming soon....</div>
    <div style={{display:"flex",justifyContent:"center" }}>
    <div onClick={()=>{navigate("/calendar")}} style={{width:"150px", padding:"10px",display:"flex",justifyContent:"center" , borderRadius: '50px',border:"none",cursor:'pointer', backgroundColor:"#ff3333", display:"flex", justifyContent:"center", alignItems:"center", color:"white"}}>Back</div>
    </div></>
  )
}

export default Dashbord2