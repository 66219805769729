import React, {useEffect, useState} from 'react'
import Header from '../Header'
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import calendervector from '../assests/calendervector.png'
import plus from '../assests/plus.png'
import '../style/reminder.css'
import Footer from '../Footer';
import gray from '../assests/grayback.png'
import red from '../assests/red.png'
import main from '../assests/Mask group.png'
import moment from 'moment';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { SuccessToast,ErrorToast } from '../helper/Toast';
// import { IoChevronBack } from 'react-icons/io'
const Reminder = () => {
    const location = useLocation();
    let params = new URLSearchParams(window.location.search);
    const navigate=useNavigate()
//    const [item,setItem]=useState('');
   const [data, setData] = useState([])
    const [serch, setSerch] = useState('')
    const [pagesize, setpagesize] = useState(Number(params.get("j")));
    const [ps, setps] = useState(pagesize);
    const [currentpage, setcurrentpage] = useState(1);
    const [tab,setTab]=useState([])
    const [category,setCategory]=useState(params.get("category"))
    const [countryName,setCountryName]=useState(params.get("countryName"))
    const [all, setAll] = useState([])
    const [calender, setCalender] = useState("")
    const [totalpage, settotalpage] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [isClickedleft, setIsClickedleft] = useState(false);
    const [limit,setLimit]=useState('')
    const [serchs,setSerchs]=useState(params.get("serch"))
    console.log('limit :>> ', limit);
    




    console.log('data :>> ', data?.name);
      const Body = {

        "search": serchs,
        "country": countryName,
        "categoryName": category,
        "limit": 1,
        "page": pagesize

    }

  
    console.log('item state:>> ', location?.state?.i);
    console.log('item index:>> ', location?.state?.j);
    console.log('item currentpage:>> ', location?.state?.currentpage)
    console.log('item pagesize:>> ', location?.state?.pagesize);

    console.log('location 1:>> ', location);

const handalleft = () =>{
    if(pagesize<=1){
    
        // console.log("no data")
        // ErrorToast("no data found")
    }else{
    setpagesize(pagesize-1)
    setIsClickedleft(true)
    setIsClicked(false)
    navigate(`/Reminder_data?j=${pagesize-1}&currentpage=${currentpage}&category=${category}&countryName=${countryName}&serch=${serchs}`)
    }
   
}
const handalright = () =>{
    if(pagesize==limit){
        // ErrorToast("no data found")
    }else{
    setpagesize(pagesize+1)
    setIsClicked(true);
    setIsClickedleft(false)
    navigate(`/Reminder_data?j=${pagesize+1}&currentpage=${currentpage}&category=${category}&countryName=${countryName}&serch=${serchs}`)
}

   
}
const handleClick = () => {
   
  };

    useEffect(() => {
        axios.post('https://api.app.specialdays.com/user/get/calender/without/tokon', Body)
            .then(res => {
                // console.log("calender data", res?.data?.data?.response)
setLimit(res?.data?.data?.page_data?.page_limit)
                setData(res?.data?.data?.response[0]?.data[0]);
           
                settotalpage(res?.data?.data?.page_data?.page_limit)
            })
            .catch(error => {
                console.error(error);
            });
    }, [pagesize]);




    const date = moment(data?.date).utc()
                  
    let today = moment().format('YYYY-MM-DD')
    let fDate = moment(new Date(data?.date)).format("YYYY-MM-DD")
    let nDate = new Date().getFullYear()
    let futureDate = moment(new Date(`${nDate}-${fDate?.split("-")[1]}-${fDate?.split("-")[2]}`)).format("YYYY-MM-DD")

    let days = Math.round(new Date(futureDate) - new Date(today) / (1000 * 60 * 60 * 24))
    let Bday = moment(futureDate).diff(moment(new Date()), "days")
    return (
        <div style={{ backgroundColor: ' #F1F5FA', height: '100%' }}>
            <Header />
            <br />
            <Container >
                <br/>
                <Row>
                    <Col>
                        <h1 style={{fontWeight:'600'}}>
                            {data?.name}
                        </h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h4 style={{ color: '#FF3333' }}>
                        {moment(data?.date).format("dddd")} , {moment(data?.date).utc().format("DD MMM YYYY")} 
                        </h4>
                    </Col>
                </Row><br /><br/>

                <Row style={{ borderRadius: '10px' }}>
                    <Col>
                        <Card sx={{ maxWidth: 700, marginLeft: '450px', borderRadius: '25px', margin: 'auto' }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={main}
                                    alt="green iguana"
                                /><br />
                                <Row>
                                    <Col md={2}  xs={2}><img src={calendervector} style={{ width: '40px', height:"40px" }} className='remindercalimg' id='reminderimg1'/></Col>
                                    <Col md={8} xs={8}>
                                        <h3>{Bday === 0 ? `${data?.categoryName} in today ` : `${data?.categoryName} in ${Bday} days`}</h3>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia similique, aspernatur repudiandae delectus provident obcaecati error tempora, voluptate blanditiis dolor porro quo laudantium illum fugiat eos et voluptas sunt enim.lore</p>
                                        <br/><br/>
                                    </Col>
                                    <Col md={2}  xs={2}><img src={plus} style={{ width: '40px' }} className='reminderplusimg'/></Col>


                                </Row>
                            </CardActionArea>
                        </Card>
                    </Col>
                </Row>


                <br />
                <Row style={{ maxWidth: 700, marginLeft: '450px', borderRadius: '10px', margin: 'auto' }}>
                    <Col  md={2} xs={2}>
                        {/* <img onClick={handalleft} src={gray} /> */}
                       <button onClick={handalleft}  className={pagesize === 1 ? ('display'):( 'clickedleft') } style={{width:'40px',height:'40px', borderRadius: '50px',border:"none",cursor:'pointer' }}><IoIosArrowBack/></button>
                        </Col>
                    <Col md={8} xs={8} style={{ margin: 'auto' }}><button style={{ color: '#ffffff', backgroundColor: '#FF3333', borderRadius: '25px', border: 'none', width: '60%', height: '50px',cursor:'pointer' }} onClick={()=>{navigate("/calendar")}}>See all events</button></Col>
                    <Col md={2} xs={2}>
                        {/* <img onClick={handalright} src={red} /> */}
                    <button  onClick={handalright}  className={pagesize === limit ? ("display") : ("clickedleft")} style={{width:'40px',height:'40px', borderRadius: '50px',border:"none",cursor:'pointer' }}><MdOutlineArrowForwardIos fontsize={10}/></button>

                    </Col>

                </Row>




            </Container>
            <br /><br />
            <Footer />
        </div>
    )
}

export default Reminder